import React from "react"
import types from "prop-types"
import clsx from "clsx"
import Popup from "reactjs-popup";
import { ensureDropdownVisible } from "utils/reactSelectHelpers"
import Dropdown from "shared/multiSelects/Dropdown"
import { CustomIconButton } from "shared/buttons";
import { Select } from "shared/selects"

const usersToOptions = (users) => users.map(({ fullName, id, ...rest }) => (
  {
    label: fullName,
    value: id,
    ...rest,
  }
))

const TaskUserSelector = ({
  addUserId,
  className,
  taskUserType,
  users,
}) => (
  <div className={clsx("flex", className)}>
    <Popup
      trigger={
        (
          <CustomIconButton
            className="w-full md:w-auto border border-light text-sm justify-end"
            icon="add-user"
            text={`Add ${taskUserType}`}
          />
        )
      }
      position="bottom left"
    >
      <div className="h-0 invisible">
        <div className="w-96">
          <Select
            backspaceDelete={false}
            className="react-dropdown-select base-input component-border-thin rounded-md -top-11"
            dropdownGap={0}
            dropdownRenderer={Dropdown(false, () => {}, `Add ${taskUserType.toLowerCase()} by name ...`)}
            keepOpen
            labelField="label"
            multi={false}
            onChange={addUserId}
            onDropdownOpen={ensureDropdownVisible}
            options={usersToOptions(users)}
            searchable
            style={{ width: "24rem" }}
          />
        </div>
      </div>
    </Popup>
  </div>
)

TaskUserSelector.defaultProps = {
  className: "",
}

TaskUserSelector.propTypes = {
  addUserId: types.func.isRequired,
  className: types.string,
  taskUserType: types.string.isRequired,
  users: types.arrayOf(types.object).isRequired,
}

export default TaskUserSelector
