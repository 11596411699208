import React, { Component, createRef } from "react"
import types from "prop-types"
import clsx from "clsx"
import Formsy from "formsy-react"
import { isEqual } from "lodash-es"
import { connect } from "react-redux"
import { updateTaskAnswer } from "reduxSlices/formSubmissionSlice"
import { deleteDraftAnswer as deleteDraft } from "reduxSlices/taskDraftAnswersSlice"
import * as API from "services/api"
import { questionShape, taskShape } from "utils/propTypeShapes"
import { MULTI_ENTRIES_TYPE, NUMBER_CALCULATION_TYPE } from "Forms/FormElementTypes"
import { PrimaryButton } from "shared/buttons"
import SubformQuestionAnswerField from "Forms/FormElements/SubformElements/SubformQuestionAnswerField"
import SubformQuestionMultiEntryAnswers from "Forms/FormElements/SubformElements/SubformQuestionMultiEntryAnswers"
import QuestionBranchingConditionalCheck from "Forms/FormElements/QuestionBranchingConditionalCheck"
import { errorToast } from "components/shared/toast"
import { simplifyAnswers } from "./TaskQuestionsValidator"

class TaskQuestion extends Component {
  state = { canSubmit: false }

  formRef = createRef()

  isMultiEntriesQuestionType = this.props.question.type === MULTI_ENTRIES_TYPE

  isNumberCalcQuestionType = this.props.question.type === NUMBER_CALCULATION_TYPE

  getFormAnswer = () => this.formRef.current?.getModel() || {}

  resetForm = () => this.formRef.current?.reset()

  onValidAnswer = () => this.setState({ canSubmit: true })

  onInvalidAnswer = () => this.setState({ canSubmit: false })

  onSubmit = async (model) => {
    const {
      deleteDraftAnswer, question, task, updateTaskAnswerInRedux,
    } = this.props

    const { uuid: questionUuid } = question
    const { id: taskId } = task

    // When an answer is edited, an update request is made to the API.  When the update occurs,
    // a message is sent over a websocket to all browsers viewing the same task.
    // The TaskAnswersSync component will respond to the message by updating redux
    // with the new answer if (1) the answered question is for multi-entry question or
    // (2) the answer is being pushed to the user (i.e., the current user did not update the answer).
    //
    // For the user updating the answer, the answer is updated in redux here,
    // before the API request is made.  Relying on the API request to trigger the websocket
    // message to trigger the redux state update was causing issues when a user
    // would make subsequent edits right around the autosave debounce time period.
    // The new edits were sometimes overwritten—for example, it would appear
    // that a letter disappeared.
    if (!this.isMultiEntriesQuestionType) {
      updateTaskAnswerInRedux({ answer: { answer: this.currentAnswer() }, id: taskId, questionUuid })
    }

    const response = await API.updateTaskAnswer({ taskId: task.id, answer: model })

    if (!response.ok) {
      console.error(response.data)
      errorToast("Something went wrong. Unable to save your answer.")
      return
    }

    if (this.isMultiEntriesQuestionType) {
      deleteDraftAnswer({ questionUuid, taskId })
      this.resetForm()
    }
  }

  currentAnswer = () => {
    const { question } = this.props
    const { uuid } = question

    return this.getFormAnswer()[uuid]
  }

  persistedAnswer = () => {
    const { question, task } = this.props
    const { uuid } = question
    const { answers } = task

    return answers[uuid]?.answer
  }

  isCurrentAnswerPersisted = () => (
    isEqual(this.persistedAnswer(), this.currentAnswer())
  )

  render() {
    const { canSubmit } = this.state

    const { className, question, task } = this.props

    const canAnswerTask = task.isOpen && task.userTaskPermissions.canAnswer
    const canSubmitForm = canSubmit && !this.isCurrentAnswerPersisted()
    const saveable = canAnswerTask && !this.isNumberCalcQuestionType

    const simplifiedAnswers = simplifyAnswers(task.answers)

    return (
      <QuestionBranchingConditionalCheck
        conditionals={question.conditionals}
        formAnswers={simplifiedAnswers}
      >
        <div className={clsx(className, "border rounded-md border-lightgray-3 p-4 task-question")}>
          <Formsy
            className={clsx(
              canAnswerTask && "bg-light-100 open-task-question",
              !canAnswerTask && "completed-task-question",
            )}
            onSubmit={this.onSubmit}
            onValid={this.onValidAnswer}
            onInvalid={this.onInvalidAnswer}
            preventDefaultSubmit
            ref={this.formRef}
          >
            {
              // general task multi-entry questions do not have prompts or descriptions
              (task.subform) && (
                <div className="flex flex-col mb-4 text-dark">
                  <span
                    className={
                      clsx(
                        question.required && "required-prompt",
                        question.description && "mb-0.5",
                        "font-medium text-lg max-w-full",
                      )
                    }
                  >
                    { question.prompt }
                  </span>
                  <span className="text-sm">
                    { question.description }
                  </span>
                </div>
              )
            }
            <div className={clsx("flex-col lg:items-center lg:flex-row rounded relative w-full flex gap-4 justify-between", !canAnswerTask && "opacity-75")}>
              <SubformQuestionAnswerField
                autosave={saveable && !this.isMultiEntriesQuestionType}
                currentAnswer={this.currentAnswer()}
                disabled={!canAnswerTask}
                persistedAnswers={simplifiedAnswers}
                taskId={task.id}
                question={question}
              />
              {
                (saveable && this.isMultiEntriesQuestionType) && (
                  <PrimaryButton
                    text="Add Comment"
                    type="submit"
                    className="shrink-0"
                    disabled={!canSubmitForm}
                  />
                )
              }
            </div>
          </Formsy>
          <SubformQuestionMultiEntryAnswers
            task={task}
            question={question}
            multiEntryAnswers={task.answers}
          />
        </div>
      </QuestionBranchingConditionalCheck>
    )
  }
}

TaskQuestion.defaultProps = {
  className: "",
  deleteDraftAnswer: () => {},
  updateTaskAnswerInRedux: () => {},
}

TaskQuestion.propTypes = {
  className: types.string,
  deleteDraftAnswer: types.func,
  question: questionShape.isRequired,
  task: taskShape.isRequired,
  updateTaskAnswerInRedux: types.func,
}

export default connect(null, { deleteDraftAnswer: deleteDraft, updateTaskAnswerInRedux: updateTaskAnswer })(TaskQuestion)
