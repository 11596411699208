import React, {
  forwardRef, useCallback, useImperativeHandle, useRef, useState,
} from "react"
import types from "prop-types"
import clsx from "clsx"
import CircularIconButton from "shared/buttons/CircularIconButton"

const ItemRow = (
  {
    collapsable = false,
    className = "",
    itemRowBody = null,
    itemRowBodyClassName = "",
    itemRowHeaderContent,
    itemRowHeaderContentClassName = "",
    itemRowHeaderControls = null,
    onCollapse = undefined,
    onExpand = undefined,
  },
  ref,
) => {
  const containerRef = useRef()

  const [collapsed, setCollapsed] = useState(true)

  const expand = useCallback(() => {
    setCollapsed(false)
    onExpand?.()
  }, [onExpand])

  const collapse = useCallback(() => {
    if (collapsable) {
      setCollapsed(true)
      onCollapse?.()
    }
  }, [collapsable, onCollapse])

  const toggleCollapsed = collapsed ? expand : collapse

  // If a ref is passed to ItemRow, then the ref's "current" value
  // will be the object returned by the function passed as the second argument
  // to useImperativeHandle().
  // https://react.dev/reference/react/useImperativeHandle
  useImperativeHandle(ref, () => (
    {
      collapse() {
        collapse()
      },
      expand() {
        expand()
      },
      scrollIntoView() {
        containerRef.current.scrollIntoView()
      },
    }
  ), [collapse, expand])

  return (
    <div className={clsx("item-row", className)} ref={containerRef}>
      <div className="item-row-header">
        <div className={clsx("item-row-header-content", itemRowHeaderContentClassName)}>
          {itemRowHeaderContent}
        </div>
        {
          itemRowHeaderControls && (
            <div className="item-row-header-controls">
              {
                collapsable && itemRowBody && (
                  <CircularIconButton icon={collapsed ? "chevron-down" : "chevron-up"} onClick={toggleCollapsed} />
                )
              }
              {itemRowHeaderControls}
            </div>
          )
        }
      </div>
      {
        itemRowBody && (
          <div className={clsx("item-row-body", itemRowBodyClassName, collapsable && collapsed && "hidden")}>
            {itemRowBody}
          </div>
        )
      }
    </div>
  )
}

ItemRow.propTypes = {
  collapsable: types.bool,
  className: types.string,
  itemRowBody: types.node,
  itemRowBodyClassName: types.string,
  itemRowHeaderContent: types.node.isRequired,
  itemRowHeaderContentClassName: types.string,
  itemRowHeaderControls: types.node,
  onCollapse: types.func,
  onExpand: types.func,
}

export default forwardRef(ItemRow)
