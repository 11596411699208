/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useCallback } from "react"
import types from "prop-types"
import { debounce, isEmpty, isEqual } from "lodash-es"

const DEBOUNCE_TIME = 1000

const isNumber = (value) => typeof value === "number"

const answerShouldBeUpdated = ({ currentAnswer, persistedAnswer }) => {
  if (isEqual(currentAnswer, persistedAnswer)) return false
  // Extra check because lodash's isEmpty() function returns true for numbers
  if (isNumber(currentAnswer) || isNumber(persistedAnswer)) return true
  if (isEmpty(currentAnswer) && isEmpty(persistedAnswer)) return false

  return true
}

const Autosave = ({
  currentAnswer = undefined,
  disabled,
  persistedAnswer = undefined,
  uuid,
}) => {
  const saveAnswer = useCallback(async () => {
    if (disabled) return

    const saveButton = document.getElementById(uuid)
    saveButton?.click()
  }, [disabled, uuid])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveAnswer = useCallback(debounce(saveAnswer, DEBOUNCE_TIME), [saveAnswer])

  useEffect(() => {
    if (answerShouldBeUpdated({ currentAnswer, persistedAnswer })) {
      debouncedSaveAnswer()
    }
  }, [currentAnswer, persistedAnswer, debouncedSaveAnswer])

  // The function returned by the callback function passed to useEffect
  // will run on every re-render if any of the dependencies change.
  // If autosave was enabled but changes to become disabled,
  // then we want to immediately invoke any pending function invocation.
  useEffect(() => (
    () => {
      if (!disabled) debouncedSaveAnswer.flush()
    }
  ), [disabled, debouncedSaveAnswer])

  if (persistedAnswer === currentAnswer) return null
  if (Array.isArray(persistedAnswer) && (isEqual(persistedAnswer, currentAnswer))) return null

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button id={uuid} type="submit" />
  )
}

Autosave.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  persistedAnswer: types.any,
  // eslint-disable-next-line react/forbid-prop-types
  currentAnswer: types.any,
  disabled: types.bool.isRequired,
  uuid: types.string.isRequired,
}

export default Autosave
