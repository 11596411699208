import React, { useEffect, useRef, useState } from "react"
import { taskShape } from "utils/propTypeShapes"
import queryParameter from "utils/queryParameter"
import * as API from "services/api"
import ItemRow from "shared/ItemRow"
import Portal from "shared/Portal"
import { taskShortcutPortalContainerId } from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/ActivitiesSidebar/index"
import { EditTaskForm } from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms"
import TaskShortcut from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/ActivitiesSidebar/TaskShortcut"
import TaskItemRowHeaderContent from "./TaskItemRowHeaderContent"
import TaskItemRowControls from "./TaskItemRowControls"
import TaskPanel from "./TaskPanel"

const Task = ({ task }) => {
  const itemRowRef = useRef()

  const { id: taskId, isDeleted: taskIsDeleted, userTaskPermissions } = task
  const { canSeeDetails: userCanSeeDetails } = userTaskPermissions

  const auditTaskView = () => API.viewTask({ taskId })

  // An abbreviated list of assignees is shown in the header content
  // when the row is collapsed.  When the row is expanded, the list
  // is not displayed to the user.
  const [assigneesVisibleInHeaderContent, setAssigneesVisibleInHeaderContent] = useState(true)
  const showAssigneesInHeaderContent = () => setAssigneesVisibleInHeaderContent(true)
  const hideAssigneesInHeaderContent = () => setAssigneesVisibleInHeaderContent(false)

  // If a user elects to edit a task, the normal representation of the task
  // is replaced by the EditTaskForm component
  const [editTaskMode, setEditTaskMode] = useState(false)
  const startEditTaskMode = () => setEditTaskMode(true)
  const endEditTaskMode = () => setEditTaskMode(false)

  const userPermittedToSeeDetails = userCanSeeDetails && !taskIsDeleted

  const openItemRowAndScrollIntoView = () => {
    itemRowRef.current?.expand()
    // If the item row doesn't have enough time to expand before scrolling,
    // then the item row does not fully scoll into view in some circumstances
    // (e.g., the last task).
    setTimeout(itemRowRef.current?.scrollIntoView, 10)
  }

  // If the task's ID is identified in a "taskId" query parameter,
  // expand the item row and scroll it into view.
  useEffect(() => {
    const initialTaskId = Number(queryParameter("taskId"))

    if (taskId === initialTaskId && userPermittedToSeeDetails) {
      console.log("HERE")
      openItemRowAndScrollIntoView()
    }
  }, [])

  // Renders two items:
  // 1. A task shortcut in the Activities Sidebar.
  // 2. Either the form for editing the task or an item row for the task.
  return (
    <>
      <Portal targetId={taskShortcutPortalContainerId(taskId)}>
        <TaskShortcut task={task} onClick={openItemRowAndScrollIntoView} />
      </Portal>
      {
        editTaskMode
          ? (
            <EditTaskForm
              className="mb-4"
              onCancel={endEditTaskMode}
              onSave={() => {
                endEditTaskMode()
                showAssigneesInHeaderContent()
              }}
              task={task}
              taskFormIsOpen={editTaskMode}
            />
          )
          : (
            <ItemRow
              ref={itemRowRef}
              collapsable
              itemRowHeaderContent={(
                <TaskItemRowHeaderContent
                  showAssignees={assigneesVisibleInHeaderContent}
                  task={task}
                />
              )}
              itemRowHeaderControls={(
                <TaskItemRowControls
                  task={task}
                  startEditTaskMode={startEditTaskMode}
                />
              )}
              itemRowBody={userPermittedToSeeDetails ? <TaskPanel task={task} /> : null}
              onCollapse={showAssigneesInHeaderContent}
              onExpand={
                () => {
                  auditTaskView()
                  hideAssigneesInHeaderContent()
                }
              }
            />
          )
      }
    </>
  )
}

Task.propTypes = {
  task: taskShape.isRequired,
}

export default Task
