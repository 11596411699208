import React from "react"
import types from "prop-types"
import { sortBy } from "lodash-es"
import { ProfileIcon } from "shared/icons"
import ItemRow from "shared/ItemRow"
import CircularIconButton from "shared/buttons/CircularIconButton"
import AccessLevelIdentifier from "./AccessLevelIdentifier"

const UserGranteeList = ({
  className = "", deleteFormSubmissionGrant, users, formSubmissionSlug, updateAccessGranteeLists, isDisabled,
}) => {
  if (!users.length) {
    return <div>No Users with Grants</div>
  }

  return (
    <div className={className}>
      {
        sortBy(users, ["grantee.lastName", "grantee.firstName"]).map(({
          grantee: user, formGrant, categoryGrant, formSubmissionGrant,
        }) => (
          <ItemRow
            key={`user-item-row-${user.id}`}
            itemRowHeaderContentClassName="item-row-header-content-submission-access"
            itemRowHeaderContent={(
              <>
                <span className="flex items-center gap-3">
                  <ProfileIcon user={user} />
                  <span className="font-semibold">{user.fullName}</span>
                </span>
                <AccessLevelIdentifier
                  formSubmissionSlug={formSubmissionSlug}
                  formSubmissionGrant={formSubmissionGrant}
                  formGrant={formGrant}
                  categoryGrant={categoryGrant}
                  updateAccessGranteeLists={updateAccessGranteeLists}
                  granteeType="User"
                  granteeId={user.id}
                  isDisabled={isDisabled}
                />
              </>
            )}
            itemRowHeaderControls={(
              <>
                {
                  !isDisabled && !!formSubmissionGrant && (
                    <CircularIconButton icon="close" onClick={deleteFormSubmissionGrant(formSubmissionGrant?.id)} />
                  )
                }
              </>
            )}
          />
        ))
      }
    </div>
  )
}

UserGranteeList.propTypes = {
  className: types.string,
  users: types.arrayOf(types.object).isRequired,
  updateAccessGranteeLists: types.func.isRequired,
  deleteFormSubmissionGrant: types.func.isRequired,
  formSubmissionSlug: types.string.isRequired,
  isDisabled: types.bool.isRequired,
}

export default UserGranteeList
