import React from "react"
import types from "prop-types"
import ItemRow from "shared/ItemRow"
import CircularIconButton from "shared/buttons/CircularIconButton"
import GroupSearchGroupMemberList from "shared/searches/SearchSelectedGroup/GroupSearchGroupMemberList"
import AccessLevelIdentifier from "./AccessLevelIdentifier"

const DepartmentGranteeList = ({
  departments, formSubmissionSlug, updateAccessGranteeLists, deleteFormSubmissionGrant, isDisabled,
}) => {
  if (!departments.length) {
    return <span>No Department with Grants</span>
  }

  return (
    departments.map(({
      grantee: department, formGrant, categoryGrant, formSubmissionGrant,
    }) => (
      <ItemRow
        key={`department-item-row-${department.id}`}
        collapsable
        itemRowHeaderContentClassName="item-row-header-content-submission-access"
        itemRowHeaderContent={(
          <>
            <span className="font-semibold">{department.name}</span>
            <AccessLevelIdentifier
              formSubmissionSlug={formSubmissionSlug}
              formSubmissionGrant={formSubmissionGrant}
              formGrant={formGrant}
              categoryGrant={categoryGrant}
              updateAccessGranteeLists={updateAccessGranteeLists}
              granteeType="Department"
              granteeId={department.id}
              isDisabled={isDisabled}
            />
          </>
        )}
        itemRowHeaderControls={(
          <>
            {
              !isDisabled && !!formSubmissionGrant && (
                <CircularIconButton icon="close" onClick={deleteFormSubmissionGrant(formSubmissionGrant?.id)} />
              )
            }
          </>
        )}
        itemRowBody={
          <GroupSearchGroupMemberList members={department.members} />
        }
      />
    ))
  )
}

DepartmentGranteeList.propTypes = {
  departments: types.arrayOf(types.object).isRequired,
  formSubmissionSlug: types.string.isRequired,
  updateAccessGranteeLists: types.func.isRequired,
  deleteFormSubmissionGrant: types.func.isRequired,
  isDisabled: types.bool.isRequired,
}

export default DepartmentGranteeList
