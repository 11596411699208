import React from "react"
import types from "prop-types"
import ReactTextFormat from "react-text-format"

const customLinkDecorator = (
  decoratedHref,
  decoratedText,
  key,
) => (
  <a
    href={decoratedHref}
    key={key}
    target="_blank"
    rel="noreferrer"
    className="customLink"
  >
    {decoratedText}
  </a>
)

const FormattedNoteAnswer = ({ note }) => (
  <ReactTextFormat
    allowedFormats={["URL"]}
    linkDecorator={customLinkDecorator}
  >
    <span value={note} className="formatted-note">
      {note}
    </span>
  </ReactTextFormat>
)

FormattedNoteAnswer.propTypes = {
  note: types.string.isRequired,
}

export default FormattedNoteAnswer
