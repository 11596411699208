import React from "react"
import types from "prop-types"
import { taskShape } from "utils/propTypeShapes"
import { CheckmarkCircleIcon } from "shared/icons"
import TaskHeaderAssigneesOverview from "./TaskHeaderAssigneesOverview"
import TaskDueDate from "./TaskDueDate"
import TaskTokenTitle from "./TaskTokenTitle"

const TaskItemRowHeaderContent = ({ showAssignees = true, task }) => {
  const {
    assigneeIds: taskAssigneeIds,
    dueDate: taskDueDate,
    isOpen: taskIsOpen,
    isOverdue: taskIsOverdue,
    subform: taskSubform,
    title: taskTitle,
  } = task

  return (
    <div className="w-full flex flex-col lg:flex-row gap-4 lg:items-center justify-between">
      <div className="flex flex-col lg:flex-row gap-4 lg:items-center">

        <div className="flex items-center gap-2 shrink-0">
          {
            taskIsOpen
              ? <div className="w-5 h-5 shrink-0 border rounded-full" />
              : <CheckmarkCircleIcon className="w-5 h-5 shrink-0" />
          }
          <TaskDueDate dueDate={taskDueDate} isOverdue={taskIsOverdue} />
        </div>
        <span className="font-medium">{taskTitle}</span>
      </div>
      <div className="flex items-center gap-4">
        <TaskTokenTitle className="shrink-0" subform={taskSubform} />
        {
          showAssignees && (
            <TaskHeaderAssigneesOverview assigneeIds={taskAssigneeIds} />
          )
        }
      </div>
    </div>
  )
}

TaskItemRowHeaderContent.propTypes = {
  showAssignees: types.bool,
  task: taskShape.isRequired,
}

export default TaskItemRowHeaderContent
